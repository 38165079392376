<template>
  <div>
    <div class="banner">
      <breadcurmb :menuList="menuList"></breadcurmb>
      <div class="title">
        <div>中心介绍</div>
        <div class="bottom-line"></div>
      </div>
      <div class="flex-center">
        <img src="~@/assets/index/logo.png" alt="" width="100" height="100">
      </div>
      <div class="content">
        <p v-html="webData.aboutUs">
        </p>
      </div>
      <div class="title">
        <div>联系方式</div>
        <div class="bottom-line"></div>
      </div>
      <div class="contact">
        <div>联系人：{{ webData.contactPerson }}</div>
        <div>联系方式：{{ webData.contactInfo }}</div>
        <div>地址：{{ webData.unitAddress }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import Breadcurmb from '@/components/breadcrumb/Breadcurmb.vue'
  import {getWebsiteInfo} from "@api/websiteInfo";

  export default {
    name: 'AboutUs',
    components: { Breadcurmb },

    data() {
      return {
        webData: {},
        menuList: [
          {
            name: '关于我们',
            path: ''
          }
        ]
      }
    },
    mounted() {
    },
    created() {
      this.loadData()
    },
    methods: {
      loadData() {
        getWebsiteInfo().then(res => {
          if (!res.success) {
            console.error(res.message)
            return
          }
          this.webData = res.result[0]
        })
        // this.webData = this.$store.state.webData
      }
    }
  }
</script>

<style scoped lang="less">
.banner {
  width: 1000px;
  height: 100%;
  margin: 85px auto 0;

  .title {
    margin: 25px 0;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .bottom-line {
      width: 30px;
      margin-top: 8px;
      border-bottom: 3px solid @primary-color;
    }
  }
}

.content {
  font-size: 20px;
  text-indent: 2em;
  text-align: justify;
  line-height: 32px;
  margin-top: 20px;
}

.contact {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  line-height: 30px;
  margin-bottom: 40px;
}
</style>
